import React from "react"
import { Button, ButtonProps } from "@chakra-ui/react"

import Link, { IBaseProps as ILinkProps } from "./Link"
import styled from "@emotion/styled"

export interface IProps extends ILinkProps, ButtonProps {}


const ButtonColor = styled(Button)`

  &:hover {
    color: ${(props) => props.theme.colors.primary};
    border: 1px solid ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.background};
  }
`

const ButtonLink: React.FC<IProps> = ({ children, ...props }) => {
  return (
    <ButtonColor as={Link} textDecoration="none" activeStyle={{}} {...props}>
      {children}
    </ButtonColor>
  )
}

export default ButtonLink
